/** @jsxImportSource theme-ui */
import * as React from "react";
import { Container } from "theme-ui";

import Image from "../components/image";
import CTA from "../components/cta";
import Navigation from "../components/navigation";

const Hero = ({ data }) => {
  const { heading, cta, tagline, heroImage, anchor } = data;

  return (
    <div
      id={anchor}
      sx={{
        bg: "primary",
        color: "#fff",
      }}
    >
      <Navigation />
      <Container variant="hero">
        <div
          sx={{
            display: "grid",
            gridTemplateColumns: ["100%", "", "repeat(2, minmax(0, 1fr))"],
            gridTemplateAreas: [
              `"image"
               "text"`,
              `"image"
               "text"`,
              `"text image"`,
            ],
            gap: ["3rem", "3rem", "7.5rem"],
            py: "3rem",
          }}
        >
          <div
            sx={{
              alignSelf: "end",
              gridArea: "text",
            }}
          >
            <h1 dangerouslySetInnerHTML={{ __html: heading }} />
            <p
              sx={{
                pt: "3rem",
                pb: "2.5rem",
                /**
                 * Minimum viewport width = 769px
                 * Maximum viewport width = 1440px
                 * Minimum font size = .8rem
                 * Maximum font size = 1.5rem
                 * 1 rem = 16px
                 */
                fontSize: "clamp(0.8rem, -0.0022rem + 1.6692vw, 1.5rem)",
                lineHeight: "1.2",
              }}
              dangerouslySetInnerHTML={{ __html: tagline }}
            />
            {/* eslint-disable-next-line */}
            <CTA cta={cta} />
          </div>
          <div
            sx={{
              aspectRatio: "0.83",
              overflow: "hidden",
              gridArea: "image",
            }}
          >
            <Image
              source={heroImage}
              sx={{
                height: "100%",
                width: "100%",
                objectFit: "cover",
              }}
            />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Hero;
