/** @jsxImportSource theme-ui */
import * as React from "react";
import { Container } from "theme-ui";
import { graphql, useStaticQuery, Link } from "gatsby";

import Logo from "./svg/logo-white";

const Navigation = () => {
  const { sanityNavigation } = useStaticQuery(graphql`
    {
      sanityNavigation(slug: { current: { eq: "main-navigation" } }) {
        items {
          ... on SanityLink {
            _key
            _type
            href
          }
          ... on SanityPage {
            id
            _key
            slug {
              current
            }
            title
          }
          ... on SanitySubMenu {
            _key
            _type
            title
            subItems {
              ... on SanityLink {
                _key
                _type
                href
              }
              ... on SanityPage {
                id
                _key
                slug {
                  current
                }
                title
              }
            }
          }
        }
      }
    }
  `);

  const navItems = sanityNavigation?.items && sanityNavigation.items;

  return (
    <Container
      variant="navigation"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        py: "2rem",
      }}
    >
      <Logo />
      <div>
        {navItems &&
          navItems.map((item) => (
            <Link
              sx={{ textDecoration: "none", color: "#fff" }}
              to={item.slug.current}
              key={item.id}
            >
              {item.title}
            </Link>
          ))}
      </div>
    </Container>
  );
};

export default Navigation;
